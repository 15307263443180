h1 {
    font-size: 32px;
    line-height: 36px;
    color: $darkblue;
    font-weight: 700;
}

h2 {
    font-size: 28px;
    line-height: 32px;
    color: $darkblue;
    font-weight: 500;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
    }
}
h3 {
    font-size: 24px;
    line-height: 28px;
    color: $darkblue;
    font-weight: 500;
    @media screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
    }
}
h4 {
    font-size: 20px;
    line-height: 26px;
    color: $green;
    font-weight: 800;
    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
    }
}
h5 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
}

span.ref { 
    color: #0A7986; 
    text-decoration-style: dotted; 
    text-decoration-line: underline; 
    cursor: pointer;
    position: relative;
}

.main, .subpage {
    p {
        &.footnote, &.reference {
            font-size: 12px;
            line-height: 15px;
            text-align: left;
            margin: 0 0 4px;
            position: relative;
            &.with-margin {
                margin: 0 0 10px;
            }
            &.gutter {
                padding-left: 5px;
                sup.first {
                    position: absolute;
                    left: 0px;
                    top: 1px;
                }
                sup.asterisk {
                    font-size: 100%;
                }
            }
        }
        &.text-left {
            text-align: left!important;
        }
    }
    a {
        color: $darkred;
        text-decoration: underline;
        &.button  {
            color: $white;
            text-decoration: none;
            &:hover {
                color: $darkred;
            }
        }
        &.no-background {
            font-size: 26px;
            line-height: 30px;
            color: $darkblue;
            font-weight: 700;
            text-align: right;
            padding-right: 25px;
            position: relative;
            margin-top: 40px;
            margin-bottom: 50px;
            display: block;
            text-decoration: none;
            @media screen and (min-width: 1200px) {
                position: relative;
                right: -5rem;
            }
            @media screen and (max-width: 1199px) {
                position: relative;
                right: 2rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 20px;
                line-height: 24px;
                padding-right: 50px;
                width: 100%;
                text-align: right;
                right: 0;
            }
            &::after {
                content: "";
                background-image: url(../img/content/caret-white.svg);
                width: 33px;
                height: 33px;
                display: block;
                position: absolute;
                background-repeat: no-repeat;
                background-color: $lightred;
                background-size: auto;
                background-position: center;
                border-radius: 50%;
                right: -25px;
                top: 0;
                padding: 10px 0 10px 10px;
                transform: rotate(-90deg);
                text-align: center;
                @media screen and (max-width: 1000px) {
                    top: calc(50% - 16.5px);
                    right: 0;
                }
            }
        }
    }
    &.container {
        max-width: 1250px;
        .row {
            // max-width: 1250px;
        }
    }
}

.main {
    h1 {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        width: 80%;
        color: $darkblue;
        margin: 0 auto 20px;
        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    h2 {
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        width: 100%;
        color: $green;
        margin: 0 auto 20px;
        @media screen and (max-width: 768px) {
            width: 80%;
            font-size: 18px;
            line-height: 22px;
        }
    }    
    p {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #636466;
        width: 80%;
        margin: 0px auto 10px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .vs {
        font-size: 32px;
        line-height: 36px;
        color: $darkblue;
        text-align: center;
        font-weight: 700;
        vertical-align: middle;
        justify-self: middle;
    }

    #review {
        h1 {
            @media screen and (max-width: 768px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .button {
            font-size: 24px;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                max-width: 220px;
                min-width: 220px;
                padding-left: 20px;
                padding-right: 50px;
            }
            &:hover {
                color: $darkred;
            }
        }
    }
}

.subpage {
    a.button {
        &:hover {
            color: $darkred;
        }
    }
    h4 {
        margin-top: 40px;
    }
    ul {
        padding-left: 20px;
        li {
            font-size: 18px;
            line-height: 22px;
            color: #636466;
            position: relative;
            list-style: none;
            padding-left: 10px;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
            }
            &::before {
                content: "•";
                color: $green;
                position: absolute;
                left: 0px;
            }
        }
    }
    p {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 22px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .primary, .secondary, .tumor, .dosing-icon {
        padding-left: 100px;
        position: relative;
        @media screen and (max-width: 768px) {
            padding-left: 80px;
        }
        p {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 22px;
        }
        strong {
            color: $green;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            background-image: url(../img/content/progression-icon.svg);
            height: 75px;
            width: 75px;
            background-repeat: no-repeat;
        }
    }

    .primary, .secondary, .tumor {
        left: 10px;
        @media screen and (max-width: 768px) {
            margin-bottom: 50px;
            left: 0;
        }
    }

    .secondary {
        &::before {
            background-image: url(../img/content/time-icon.svg);
        }
    }
    
    .tumor {
        &::before {
            background-image: url(../img/content/tumor-icon.svg);
        }
    }
    .pillbox {
        h4 {
            margin: 0;
            margin-top: 8px;
        }
        p {
            font-weight: normal;
        }
        &::before {
            background-image: url(../img/content/pill-box-icon.svg);
        }
    }

    .temp {
        h4 {
            margin: 0;
            margin-top: 8px;
        }
        p {
            font-weight: normal;
        }
        &::before {
            background-image: url(../img/content/temp-icon.svg);
        }
    }

    .card-icon, .publication-icon {
        padding-left: 120px!important;
        font-size: 26px;
        @media screen and (max-width: 768px) {
            padding-left: 20px!important;
        }
        h3 {
            font-size: 26px;
            line-height: 30px;
            @media screen and (max-width: 768px) {
                padding-left: 120px!important;
                font-size: 20px;
                line-height: 24px;
            }
        }
        p {
            font-weight: 400;
        }
        a {
            font-size: 18px;
            font-weight: 700;
            @media screen and (max-width: 768px) {
                &.button {
                    width: 180px;
                    min-width: 180px;
                }
            }
        }
        &::before {
            width: 88px;
            height: 88px;
            background-size: contain;
            left: 20px;
            top: 20px;
            background-image: url(../img/content/card-icon.svg);
        }
    }
    

    .publication-icon {
        @media screen and (max-width: 768px) {
            p {
                @media screen and (max-width: 768px) {
                    padding-left: 120px!important;
                }
            }
        }
        &::before {
            background-image: url(../img/content/publication-icon.svg);
        }
    }

    .dosing-icon {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        align-self: top;
        padding-left: 70px;
        margin: 30px 20px;
        @media screen and (max-width: 768px) {
            min-height: 50px;
            display: block;
            font-size: 16px;
            line-height: 20px;
            margin-left: 0;
        }
        
        span {
            position: relative;
            top: 5px;
        }
    }

    .downloads {
        text-align: center;
        margin-bottom: 40px;
        img {
            width: 150px;
            margin-bottom: 15px;
        }
        h3 {
            text-align: center;
        }
        a.download {
            line-height: 25px;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
            a.download {
                width: 156px;
                min-width: 156px;
                font-size: 16px;
                line-height: 23px;
                padding-top: 15px;
                padding-left: 0px;
                &::after {
                    background-size: 13px 13px;
                    top: 18px;
                    right: 15   px;
                }
            }
        }
        .column {
            @media screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 10px;
                text-align: center;
            }
        }
    }

    .blue-box {
        background-color: #EDF7F8;
        border-radius: 10px;
        margin: 0 10px;
        padding: 20px;
        @media screen and (max-width: 768px) {
            margin-bottom: 50px;
            width: calc(100% - 20px);
        }
    }

    .endpoints {
        margin-top: 50px;
        margin-bottom: 20px;
        h4 {
            margin-top: 0;
            position: absolute;
            top: -40px;
            left: 0px;
        }
    }

    #secondary-endpoint {
        margin-top: 30px;
    }

    &.results {
        .primary, .secondary, .tumor {
            padding-bottom: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
            &::before {
                height: 93px;
                width: 93px;
                background-size: contain;
                @media screen and (max-width: 768px) {
                    height: 70px;
                    width: 70px;
                }
            }
        }
        .tumor {
            padding-bottom: 40px;
            h3 {
                padding-top: 25px;
                margin-bottom: 0;
            }
        }
    }

    &.access, &.resources {
        .downloads { 
            a.download {
                font-size: 16px;
                width: 155px;
                min-width: 155px;
                padding: 10px 25px;
                height: 50px;
                text-align: left;
                &::after {
                    top: 15px;
                }
            }
        }
        .secondary-header {
            @media screen and (max-width: 768px) {
                padding-top: 35px;
            }
        }
    }

    
    &.resources {
        @media screen and (max-width: 1230px) {
            .button.download {
                line-height: unset
            }
            h3 {
                min-height: 48px;
            }
        }
        @media screen and (max-width: 768px) {
            .downloads { 
                .column-33:first-of-type {
                    margin-bottom: 50px;
                }
            }
            h3 {
                min-height: unset;
            }
        }
    }

    @media screen and (max-width: 1230px) {
        a.button {
            padding-top: 0;
            height: 54px;
            width: 292px;
            line-height: 48px;
            &::after {
                top: 16px;
            }
        }
    }
}
#main .row:first-child{ 
    @media screen and (max-width: 1000px) {
        padding: 20px;
    }
}
#section-next {
    text-align: center;
    padding: 70px 10% 40px;
    #next-wrapper { border-radius: 20px; background-color: rgba(0, 163, 154, 0.10); padding: 40px 50px 40px; }
    #next-title { font-size: 28px; line-height: 32px; font-weight: 500; color: #666666; margin-bottom: 20px; }
}

.wrapper-bottom-gradient {
    background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(183, 221, 226, 0.35) 100%);
    @media screen and (max-width: 768px) {
        background-size: 100% 70%;
    }
}

.row { 
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}