footer {
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    color: #fff;
    margin-top: 50px;
    padding: 50px 10px;
    position: relative;
    z-index: 1;
    background: rgb(27,54,103);
    background: -moz-linear-gradient(90deg, rgba(27,54,103,1) 0%, rgba(0,163,154,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(27,54,103,1) 0%, rgba(0,163,154,1) 100%);
    background: linear-gradient(90deg, rgba(27,54,103,1) 0%, rgba(0,163,154,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b3667",endColorstr="#00a39a",GradientType=1);
    .container { 
        max-width: 1212px;
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    nav {
        ul, li {
            text-decoration: none;
            display: inline-block;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        li {
            position: relative;
            padding-right: 40px;
            a {
                color: #fff;
                text-decoration: underline;
                font-weight: 700;
            }
            &::after {
                content: "|";
                position: absolute;
                right: 15px;
            }

            @media screen and (max-width: 768px) {
                padding-right: 10px;
                &::after { right: 0px; }
            }

            @media screen and (max-width: 389px) {
                &::after { display: none; }
            }

            @media screen and (max-width: 382px) {
                width: 100%;
                padding-bottom: 20px;
                &:last-of-type {
                    padding-bottom: 0;
                }
            }


            &:last-of-type::after {
                content: unset;
            }
        }
        ul.isi-links {
            li {
                @media screen and (max-width: 639px) {
                    &:first-of-type {
                        padding-bottom: 20px;
                    }
                }
                @media screen and (max-width: 498px) {
                    &:nth-child(2)::after { 
                        display: none;
                    }
                }
            }
        }
        padding-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        .row {
            .column.column-75 {
                flex: 0 0 100%;
                max-width: 100%;   
            }
            .column {
                text-align: center;
                img {
                    margin-bottom: 15px;
                }
            }
            &:last-of-type {
                flex-direction: column-reverse;
            }
        }
    }
}