.hero.glioma-videos-and-resources {
	margin-bottom: 0px;
	@media screen and (max-width: 768px) {
		h1 { width: 60%; }
	}
}
#main.glioma-videos-and-resources {
	p { margin-bottom: 5px; color: #636466;
		&.reference {
			font-size: 12px;
			line-height: 14px;
			position: relative;
			padding-left: 5px;
			color: #636466;
		}
	}
	.text-left {
		text-align: left!important;
	}
	
	.icon { width: 81px; height: 86px; background-position: center top; background-repeat: no-repeat; margin: 10px auto; }
	.container_gradient { background-image: linear-gradient(0deg, rgba(183, 221, 226, 0.4) 0%, #FFFFFF 100%); }
}

.glioma-videos-and-resources #servierONE {
	@media screen and (max-width: 768px) {
		> div { 
			margin: 0px 10px!important;
			width: calc(100% - 20px)!important;
		}
	}
	#container-more_info > div {
		flex-direction: row;
		text-align: left;
		@media screen and (max-width: 1000px) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		.icon { margin-right: 10px;
			@media screen and (max-width: 1000px) {
				margin: 0 auto 5px;
			}
		}
	}
}

#gradient-row {
	background-image: linear-gradient(90deg, #1B3667 0%, #00A39A 100%);
	padding: 60px 0px;
	@media screen and (max-width: 768px) {
		padding: 30px 0;
	}
	h2, h3, p {
		color: #FFFFFF;
	}
	@media screen and (min-width: 768px) {
		h2 {
			font-size: 38px;
			margin-bottom: 60px;
		}
	}
	@media screen and (max-width: 768px) {
		.column {
			padding: 20px;
		}
	}
	.row-story {
		padding-bottom: 50px;
	}
	#story-content {
		align-content: center;
		h3 {
			font-size: 36px;
		}
		p {
			font-family: "Fira Sans", serif;
			font-weight: 300;
			font-size: 28px;
			line-height: 34px;
			color: #fff;
		}
	}
	.title {
		color: #FFFFFF;
		font-size: 24px;
		line-height: 30px;
	}
	.description {
		font-size: 18px;
		line-height: 22px;
		padding: 15px 15px 15px 0px;
	}
	.voranigo-doctor-discussion-guide {
		p {
			color: #fff!important;
		}
		@media screen and (max-width: 638px) {
			.button {
				float: right;
				margin-right: 20px;
			}
		}
	}
	.cover { width: 131px; height: 179px; background-size: 131px 179px; background-repeat: no-repeat; background-position: left top; float: left; margin-right: 20px; }
	.cover-voranigo-doctor-discussion-guide { background-image: url(../img/glioma-videos-and-resources/voranigo-doctor-discussion-guide.jpg); }
}

.cover-patient-brochure { background-image: url(../img/glioma-videos-and-resources/voranigo-patient-brochure.jpg); }
.cover-doctor-discussion-guide { background-image: url(../img/glioma-videos-and-resources/voranigo-doctor-discussion-guide.jpg); }

#tools-that-can-help {
	padding: 60px 0px 0;
	@media screen and (max-width: 911px) {
		padding-top: 0;
	}
	h2 {
		margin-bottom: 15px;
	}
	p { color: #636466!important;  }
	.tools-downloads { margin-top: 50px;
		@media screen and (min-width: 911px) {
			.column { margin-right: 32px }
		}
		@media screen and (max-width: 911px) {
			flex-direction: column;
			max-width: 420px;
			padding-left: 20px;
			padding-right: 20px;
			.column {
				margin-bottom: 30px;
			}
			a.button {
				float: right;
				margin-right: 20px;
			}
		}
	}
	.cover { width: 131px; height: 179px; background-size: 131px 179px; background-repeat: no-repeat; background-position: left top; float: left; margin-right: 20px; }
	.cover-my-glioma-guide { background-image: url(../img/glioma-videos-and-resources/my-glioma-guide.jpg); }
	.cover-glioma-caregiver-guide { background-image: url(../img/glioma-videos-and-resources/glioma-caregiver-guide.jpg); }
	.title { color: #1B3667; font-size: 24px; line-height: 26px; font-weight: 500; margin-bottom: 5px; }
	.description { font-size: 16px; line-height: 19px; color: #636466; padding-bottom: 15px; }
	.description-narrow { max-width: 515px;
		@media screen and (max-width: 911px) {
			margin-bottom: 0;
		}
	}
}