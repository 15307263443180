.hero.financial-assistance-and-glioma-support {
	@media screen and (max-width: 768px) {
		h1 { width: 80%; padding-right: 15%; }
	}
}
#main.financial-assistance-and-glioma-support {
	.logo-servierONE { max-width: 290px; padding: 0px 20px; 
		@media screen and (max-width: 768px) {
			padding: 0;
		}
	}
	p { margin-bottom: 5px;
		&.reference {
			font-size: 12px;
			line-height: 14px;
			position: relative;
			padding-left: 5px;
			color: #636466;
		}
	}
	.text-left {
		text-align: left!important;
	}
	.container-green-outline {
		background-color: #FFFFFF;
		border: 1px solid #00A39A;
		border-radius: 20px;
		padding: 20px;
		max-width: 1070px;
		margin: 45px auto 70px;
		@media screen and (max-width: 1080px) {
			margin: 45px 25px 70px;	
		}
		.row { justify-content: center; }
		.support-services { max-width: 750px; 
			@media screen and (max-width: 768px) { 
				.column:first-of-type {
					margin-bottom: 20px;
				}
			}
		}
		#container-more_info-title, #container-more_info { border: none; }
		h2, .legend, .note { text-align: center; }
		.legend { font-size: 24px; line-height: 26px; font-weight: 500; color: #1B3667; }
		.note { font-size: 16px; line-height: 19px; color: #1B3667; margin: 30px auto 45px; }
	}
	.icon { width: 81px; height: 86px; background-position: center top; background-repeat: no-repeat; margin: 10px auto; }
	.icon-checkmark { background-image: url(../img/financial-assistance-and-glioma-support/icon-check.svg); }
	.icon-dollar-sign { background-image: url(../img/financial-assistance-and-glioma-support/icon-dollar-sign.svg); }
	.container_gradient { background-image: linear-gradient(0deg, rgba(183, 221, 226, 0.4) 0%, #FFFFFF 100%); }
}
#logo-servierONE-desktop {
	@media screen and (max-width: 768px) { display: none; }
}
#logo-servierONE-mobile {
	width: 208px;
	margin: 25px auto 30px; 
	@media screen and (min-width: 769px) { display: none; }
}
.financial-assistance-and-glioma-support #servierONE {
	@media screen and (max-width: 768px) {
		> div { 
			margin: 0px 10px!important;
		}
	}
	#container-more_info > div {
		flex-direction: row;
		text-align: left;
		@media screen and (max-width: 1000px) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		.icon { margin-right: 10px;
			@media screen and (max-width: 1000px) {
				margin: 0 auto 5px;
			}
		}
	}

}

#copay-program {
	padding-bottom: 75px;
	.row {
		max-width: 1000px;
		.column {
			background-image: url(../img/financial-assistance-and-glioma-support/icon-savings-card.svg);
			background-size: 88px 86px;
			background-repeat: no-repeat;
			background-position: left top;
			padding-left: 110px;
			@media screen and (max-width: 768px) {
				background-position: center top;
				padding: 110px 20px 0px;
				text-align: center;
				.program-eligibility {
					text-align: left;
				}
			}
		}
	}
	h2 { margin-bottom: 15px; }
	.button { margin: 15px 20px 20px 0px;
		@media screen and (max-width: 768px) {
			margin-right: 0;
		}
	}
	.program-eligibility { color: #1B3667; }
	.bullet { margin-left: 10px; } 
	.bullet::before { content: "•"; color: #00A39A; font-size: 16px; margin-left: -10px; margin-right: 5px; }
}

#patient-support-assistance {
	// background-image: linear-gradient(180deg, rgba(183, 221, 226, 0.4) 0%, #FFFFFF 100%);
	h2, p { text-align: center; max-width: 1024px; margin: 0px auto; }
	h2 { margin: 65px auto 20px; }
	h3 { font-size: 24px; line-height: 28px; font-weight: 600; text-align: center; color: #0A7986; margin-bottom: 30px; }
	.name { font-size: 18px; line-height: 22px; font-weight: 700; text-align: center; color: #0A7986; margin-bottom: 15px; }
	.column { display: flex; flex-direction: column; align-items: center; }
	.logo { width: 238px; height: 113px; background-position: center bottom; background-repeat: no-repeat; margin-bottom: 15px; }
	.logo-american-brain-tumor-association { background-image: url(../img/financial-assistance-and-glioma-support/logo-american-brain-tumor-association.jpg); background-size: 224px auto; }
	.logo-end-brain-cancer-initiative { background-image: url(../img/financial-assistance-and-glioma-support/logo-end-brain-cancer-initiative.jpg); background-size: 208px auto; }
	.logo-national-brain-tumor-society { background-image: url(../img/financial-assistance-and-glioma-support/logo-national-brain-tumor-society.jpg); background-size: 211px auto; }
	.logo-oligo-nation { background-image: url(../img/financial-assistance-and-glioma-support/logo-oligo-nation.jpg); background-size: 238px auto; }
	.logo-caancer-care { background-image: url(../img/financial-assistance-and-glioma-support/logo-caancer-care.jpg); background-size: 175px auto; }
	.logo-triage-cancer { background-image: url(../img/financial-assistance-and-glioma-support/logo-triage-cancer.jpg); background-size: 235px auto; }
	.button { margin-bottom: 50px; }
	.footnote { max-width: 1070px; margin: 0px auto; font-size: 12px; line-height: 14px; font-style: italic; text-align: left;
		@media screen and (max-width: 1080px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.margin-bottom-20 { margin-bottom: 30px!important; }
}

.download-patient-brochure {
	justify-content: center;
	max-width: 640px;
	padding: 0px 25px;
	.guide-title {
		font-size: 24px;
		line-height: 26px;
		color: #1B3667;
		font-weight: 500;
	}
	p {
		font-size: 16px!important;
		line-height: 19px!important;
		padding: 10px 35px 10px 0px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: row;
	}
}
.guide-cover {
	min-width: 143px;
	position: relative;
	left: -20px;
	img {
		display: block;
	}
}