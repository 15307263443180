.hero.about-voranigo {
	h1 {
		@media screen and (max-width: 768px) {
			width: 130px;;
		}
	}
	.container {
		&::after {
			bottom: 0;
		}
	}
}
#about_voranigo-card-container-desktop {
	width: 100%;
	min-width: 590px;
	margin-right: 30px;
	@media screen and (max-width: 911px) {
		display: none;
	}
}
#about_voranigo-card-desktop {
	border: 1px solid $green;
	border-radius: 20px;
	display: flex;
	justify-content: flex-end;
    background-image: url(../img/about-voranigo/brain-target.png);
    background-repeat: no-repeat;
    background-position: 30px bottom;
    background-size: 310px 278px;
    padding: 20px 20px 0px 20px;
	p { font-size: 20px; font-weight: 600; line-height: 24px; color: $green; margin-left: 330px; margin-top: 35px; padding-bottom: 30px; }
}

#about_voranigo-card-container-mobile {
	width: 100%;
	min-width: 320px;
	max-width: 350px;
	margin: 0px auto 20px;
	@media screen and (min-width: 912px) {
		display: none;
	}
}
#about_voranigo-card-mobile {
	border: 1px solid $green;
	border-radius: 20px;
	display: flex;
	margin: 0px auto;
	height: 163px;
	justify-content: flex-end;
    background-image: url(../img/about-voranigo/brain-target.png);
    background-repeat: no-repeat;
    background-position: 10px bottom;
    background-size: 163px 153px;
    padding: 20px 20px 150px 20px;
	p { font-size: 12px; font-weight: 600; line-height: 14px; color: $green; margin-left: 163px; margin-top: 10px;
		@media screen and (max-width: 390px) {
			margin-top: -10px;
		}
	}
}

#main.about-voranigo {
	.column:first-of-type {
		max-width: 550px;
		margin-right: 50px;
		@media screen and (max-width: 911px) {
			max-width: 100%;
			margin: 0px auto;
			padding: 0px;
		}
	}
	.download-patient-brochure { max-width: 100%; margin-left: auto; margin-right: auto; align-items: center;
		@media screen and (max-width: 911px) {
			flex-direction: row;
			justify-content: center;
			padding: 0px 25px 70px;
		}
	}
	.guide-cover { margin-right: 35px;
		@media screen and (max-width: 768px) {
			width: 107px;
			height: 108px;
			margin-right: 20px;
			left: 0;
			img { width: 143px; height: 180px;}
		}
	}
	.guide-title { font-size: 28px; line-height: 30px; font-weight: 500; color: #1B3667; max-width: 350px; margin-bottom: 10px;
		@media screen and (max-width: 768px) {
			font-size: 18px; line-height: 18px; max-width: 233px;
		}
	}
	+ .wrapper-bottom-gradient #section-next {
		padding-top: 5px;
	}
}

#about-voranigo-why.why-voranigo-was-right {
	width: 822px;
	height: 245px;
	background-image: url(../img/about-voranigo/bg-why-rob-desktop.jpg);
	background-position: right;
	background-size: 100%;
	background-repeat: no-repeat;
	margin: 0px auto;
	display: flex;
	align-items: flex-end;
	color: #FFFFFF;
	padding-left: 45px;
	padding-bottom: 45px;
	@media screen and (max-width: 768px) {
		background-image: url(../img/about-voranigo/bg-why-rob-mobile.jpg);
		background-position: center bottom;
		max-width: 348px;
		width: 100%;
		height: 440px;
		padding: 25px 50px 35px;
	}
	p {
		font-size: 28px;
		font-weight: 500;
		@media screen and (max-width: 768px) {
			font-size: 24px;
			line-height: 26px;
		}
	}
	.button {
		border-color: #FFFFFF;
		@media screen and (max-width: 768px) {
			display: flex;
			justify-self: center;
			max-width: 172px;
    	margin: 0 auto;
		}
	}
}