// .overlay-isi { display: none!important; }
[data-tippy-root] { 
    z-index: 14!important; 
    color: #636466;
    background-color: #DEF3F4;
    padding: 20px;
    border-radius: 20px;
    white-space: normal; /* Allow text to wrap */
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 0px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.25);
    pointer-events: all;
    .ref_title { color: #1B3667; font-weight: 800; margin-bottom: 10px;  }
    .tippy-arrow { background-color: #DEF3F4; color: #DEF3F4; height: 25px; }
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background-image: url(../img/content/close.svg);
        background-size: 27px;
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
}
