.hero {
    @media screen and (max-width: 768px) {
        &.home {
            height: 355px;
            h1, .highlight {
                max-width: 270px;
            }
            .container {
                transform: unset;
                top: 30px;
            }
            .highlight {
                margin-top: 0px;
                margin-bottom: 45px;;
                line-height: 24px;
            }
        }
    }
}
#container-speaker {
    display: inline-block;
    width: 28px;
    height: 20px;
    line-height: 0px;
    margin-right: 8px;
    #speak-voranigo { padding-bottom: 3px; }
    @media screen and (max-width: 768px) {
        width: 14px;
        height: 10px;
        margin-right: 5px;
    }
}
#say-voranigo {
    bottom: 1px;
    border-bottom: 2px dotted #FFFFFF;
    cursor: pointer;
    display: inline-block;
}

.content-callout {
    background-color: $lightblue;
    border-radius: 10px;
    padding: 25px 50px;
    margin: 25px 40px;
    .row {
        margin-top: 12px;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        .column {
            &:first-of-type {
                padding-right: 50px;
            }
        }
    }
    h3 {
        color: $darkblue;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 20px;
        margin: 10px 0;
        h3 {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.servier-one-container {
    margin-top: 25px;
    position: relative;
    p {
        font-size: 18px;
        line-height: 22px;
    }
    .column-34 {
        img.servier-one-logo {
            display: block;
        }
    }
    .column-66 {
        img.servier-one-logo {
            display: none;
            margin: 20px auto;
        }
    }
    @media screen and (max-width: 768px) {
        .column-34 {
            flex: 0 0 100%;
            max-width: 100%;
            img.servier-one-logo {
                display: none;
            }
        }
        .column-66 {
            flex: 0 0 100%!important;
            max-width: 100%!important;
            img.servier-one-logo {
                display: block;
                margin-bottom: 20px;
                width: 157px;
                height: 78px;
            }
        }
        h2 {
            font-size: 24px;
            line-height: 28px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.phone-callout {
    font-size: 20px;
    line-height: 23px;
    h3 {
        color: $green;
        font-size: 20px;
        line-height: 26px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 10px;
        &::after {
            display: none!important;
        }
    }
    > span {
        position: relative;
        top: 5px;
    }
    small {
        font-size: 11px;
        font-weight: 700;
        position: relative;
        top: -5px;
        display: block;
        width: 100%;
        span {
            font-variant: small-caps;
            font-size: 0.8em;
        }
    }
}

.column {
    &.tick, &.dollar, &.prescription, &.tools, &.website, &.phone {
        padding-left: 70px;
        position: relative;
        min-height: 50px;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            background-repeat: no-repeat;
            background-size: contain;
            height: 50px;
            width: 50px;
        }
    }
    &.tick, &.dollar, &.prescription, &.tools {
        font-size: 18px;
        line-height: 22px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }
    &.tick::before {
        background-image: url("../img/content/tick.svg");
    }
    &.prescription::before {
        background-image: url("../img/content/prescription.svg");
    }
    &.dollar::before {
        background-image: url("../img/content/dollar.svg");
    }
    &.tools::before {
        background-image: url("../img/content/tools.svg");
    }
    &.website {
        padding-top: 12px;
        a {
            font-weight: 700;
        }
        &::before {
            background-image: url("../img/content/website.svg");
            width: 32px;
            height: 41px;
            top: 7px;
        }
    }

    &.phone {
        padding-top: 2px;
        a {
            text-decoration: none;
            font-weight: 700;
        }
        &::before {
            background-image: url("../img/content/phone.svg");
            width: 44px;
            height: 44px;
            top: 3px;
        }
    }
    &.or {
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
    }
    @media screen and (max-width: 768px) {
        &.website, &.phone {
            flex: 0 0 100%!important;
            max-width: 300px!important;
            padding: 10px 50px;
        }
        &.website, &.phone, &.or {
            text-align: center;
            &::before {
                height: 44px;
                width: 44px;
            }
        }
    }
}

.register-callout, .embryo-callout {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    h3, h4 {
        font-size: 20px;
        line-height: 24px;
        color: $darkblue;
        font-weight: 500;
        position: relative;
        display: inline-block;
        a {
            font-weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/home/circle.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 50px;
            height: 60px;
            left: -25px;
            top: -15px;
        }

        @media screen and (max-width: 768px) {
            width: 275px;
            text-align: left;
            &::before {
                left: -2em;
                top: -2em;
                width: 9em;
                height: 9em;
            }
        }
    }
}

.embryo-callout {
    align-self: center;
    margin-top: 0;
    h4 {
        &::before {
            width: 85px;
            height: 100px;
            top: -25px;
            left: -45px;
            @media screen and (max-width: 768px) {
                width: 125px;
                height: 147px;
                left: -70px;
                top: -20px;
            }
        }
    }
}

#review {
    margin: 50px 0;
}

#home-cards .home-main {
    @media screen and (max-width: 768px) {
        padding: 20px 40px 20px 20px!important;
    }
}
.home-main .ref { color: #FFFFFF; }

#home-story {
    border: 1px solid #00A39A;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    margin: 0px 60px 60px;
    @media screen and (max-width: 1160px) {
        flex-direction: column;
        max-width: 714px;
        justify-self: center;
        margin: 0px 5% 20px;    
        text-align: center;
        h3 {
            text-align: left;
        }
    }
    p {
        color: #1B3667!important;
    }
    #home-story-video {
        width: 65%;
        height: auto;
        padding: 0px;
        position: relative;
        @media screen and (max-width: 1160px) {
            width: 100%;
            justify-content: center;
        }
        .web-video {
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 20px;
            bottom: -1px;
            right: 1px;
            height: calc(100%);
            @media screen and (max-width: 1160px) {
                width: 100%;
                position: unset;
            }
        }
        #home-story-video-frame {
            position: relative;
            top: 0px;
            width: 720px;
            height: 395px;
            background-image: url(../img/home/story_frame-desktop.png);
            background-size: 714px 402px;
            background-position: right bottom;
            background-repeat: no-repeat;
            @media screen and (max-width: 1160px) {
                width: 100%;
                background-image: url(../img/home/story_frame-mobile.png);
                background-size: cover;
                background-position: center bottom;
                position: absolute;
                bottom: -2.5em;
                // min-height: 400px;
                height: auto;
            }
        }
    }
    #home-story-content {
        width: 34%;
        padding-left: 30px;
        h3 { font-size: 28px;
            @media screen and (max-width: 1160px) {
                text-align: center;
            }
        }
        @media screen and (max-width: 1160px) {
            width: 90%;
            padding-bottom: 30px;
            padding-top: 2.5em;
            padding-left: 5%;
            padding-right: 5%;
        }
        p {
            margin: 0px;
            margin-bottom: 20px;
        }
    }
}