table {
    max-width: 920px;
    span {
        color: $lightred;
        .break-mobile {
            display: inline;
        }
        @media screen and (max-width: 768px) {
            .break-mobile {
                display: block;
            }
        }
    }
    .indent {
        padding-left: 20px;
        display: block;
    }
    tr {
        th {
            line-height: 18px;
        }
        td {
            line-height: 18px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        th, td {
            &.bggrey {
                background-color: #F0F3F5!important;
            }
            &.bggreen {
                background-color: #E9F5F6!important;
            }
            &.bgwhite {
                background-color: $white!important;
            }
            font-size: 18px;
            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }
    &.ndc {
        max-width: 100%;
        position: relative;
        &::before, &::after {
            content: "";
            background: rgb(27,54,103);
            background: linear-gradient(90deg, rgba(27,54,103,1) 0%, rgba(0,163,154,1) 100%);
            height: 1px;
            display: block;
            position: absolute;
            width: 100%;
        }
        &::before {
            top: -1px;
        }

        &::after {
            bottom: 0px;
        }
        tr {
            td, th {
                font-size: 18px;
                line-height: 22px;
                font-weight: normal;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 20px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
                &:first-child {
                    font-weight: 700;
                    background-color: $darkblue;
                    color: $white;
                    text-align: right;
                    padding-right: 10px;
                    width: 250px;
                    @media screen and (max-width: 768px) {
                        width: 155px;
                    }
                }
            }
            th {
                &:last-child {
                    border-left: 1px solid $green;
                }
            }
            td {
                &:last-child {
                    border-left: 1px solid $green;
                }
            }
        }
        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: #E9F5F6;
                }
                td {
                    @media screen and (max-width: 768px) {
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }
        }

        &.mobile {
            border-top: 1px solid $green;
            border-bottom: 1px solid $green;
            tr {
                &:nth-of-type(odd) {
                    background-color: $white;
                }
                &:nth-of-type(even) {
                    background-color: #E9F5F6;
                }
                &:last-child {
                    td { border-bottom: none; }
                }
            }
        }
    }
    &.recommended, &.adjusted {
        max-width: 900px;
        thead {
            tr {
                background-color: $green;
                th {
                    color: $white;
                    padding: 5px 10px;
                    border-left: 1px solid $white;
                }
            }
        }
        tbody {
            tr {
                &:nth-of-type(even) {
                    background-color: #E9F5F6;
                }
                td {
                    color: #666666;
                    padding: 5px 10px;
                    border-left: 1px solid $green;
                    border-bottom: none;
                    &:first-child {
                        border-left: none;
                    }
                    &:last-child {
                        border-radius: none;
                    }
                }
                &:last-of-type {
                    td {
                        border-bottom: 1px solid $green;
                    }
                }
            }
        }
    }
    &.adjusted {
        max-width: unset;
        strong {
            display: block;
        }
        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: #E9F5F6;
                }
                &:nth-of-type(even) {
                    background-color: $white;
                }
                td {
                    border-bottom: 1px solid $green;
                    font-size: 18px;
                    line-height: 22px;
                    @media screen and (max-width: 768px) {
                        font-size: 15px;
                        line-height: 18px;
                    }
                    &.no-padding {
                        padding: 0!important;
                        margin: 0!important;
                    }
                    &.white {
                        background-color: $white;
                    }
                    &:first-child {
                        width: 35%;
                    }
                }
                table {
                    padding: 0;
                    margin: 0;
                    thead {
                        tr {
                            th {
                                font-size: 18px;
                                line-height: 22px;
                                color: #666666;
                                font-weight: normal;
                                border: none;
                                background-color: $white;
                                @media screen and (max-width: 768px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                    tr {
                        &:nth-of-type(even) {
                            background-color: $white;
                        }
                        &:nth-of-type(odd) {
                            background-color: #E9F5F6;
                        }
                        td {
                            border-bottom: none!important;
                            
                            &:first-child {
                                width: 50%;
                            }
                            width: 50%;
                        }
                    }
                }
            }
        }
        &.mobile {
            tr {
                th {
                    border-top: 1px solid $green;
                    border-left: 1px solid $green;
                    border-bottom: 0;
                    vertical-align: top;
                    &:first-child {
                        text-align: right;
                    }
                    &:last-child {
                        font-weight: normal;
                        background-color: $white;
                        color: $tablegrey;
                    }
                }
                td {
                    &:first-child {
                        color: $white;
                        font-weight: 700;
                        background-color: $green;
                        vertical-align: top;
                        text-align: right;
                    }
                    &:last-child {
                        font-weight: normal;
                        color: $tablegrey;
                    }
                    ul {
                        padding-left: 10px;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        li {
                            margin-bottom: 0
                        }
                    }
                    p {
                        margin-bottom: 6px;
                        strong {
                            display: inline;
                        }
                    }
                    .spacing {
                        padding-bottom: 6px;
                    }
                }
            }
        }
    }
    &.adverse {
        .indent {
            font-weight: normal;
        }
        span {
            color: inherit;
        }
        th, td {
            padding: 5px;
            &:first-of-type {
                padding-left: 10px;
                @media screen and (max-width: 768px) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                @media screen and (max-width: 420px) {
                    word-wrap: wrap;
                    span {
                        word-wrap: break-word;
                        text-wrap: pretty;
                        width: 100px;
                        inline-size: 100px;
                        hyphens: manual;
                    }
                }
            }
        }
        thead {
            tr {
                th {
                    color: $white;
                    text-align: center;
                    span {
                        color: $white;
                        font-weight: normal;
                        display: block;
                    }
                    &:first-child {
                        background-color: $white;
                        border-bottom: 1px solid $green;
                    }
                    &.green {
                        background-color: $green;
                        border-bottom: 1px solid $green;
                        border-left: 1px solid $white;
                    }
                    &.grey {
                        background-color: #636466;
                        border-bottom: 1px solid #636466;
                        border-left: 1px solid $white;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    color: $green;
                    border-bottom: 0;
                    &[colspan="5"] {
                        border-top: 1px solid $green;
                    }
                }
                &:first-of-type {
                    td {
                        font-weight: 700;
                        color: $green;
                        span {
                            font-weight: normal;
                            display: block;
                        }
                        &:nth-of-type(2), &:nth-of-type(3) {
                            background-color: #E9F5F6;
                            &.bgwhite {
                                background-color: $white!important;
                            }
                        }
                        &:nth-of-type(4) {
                            border-left: 1px solid #666666;
                        }
                        &:nth-of-type(4), &:nth-of-type(5) {
                            background-color: #F0F3F5;
                            &.bgwhite {
                                background-color: $white!important;
                            }
                        }
                    }
                }
                &:last-of-type {
                    td {
                        border-bottom: 1px solid $green;
                    }
                }
                td {
                    color: #636466;
                    
                    &:nth-of-type(2) {
                        border-left: 1px solid $green;
                    }
                    &:nth-of-type(2), &:nth-of-type(3) {
                        background-color: #E9F5F6;
                        text-align: center;
                        &.bgwhite {
                            background-color: $white!important;
                        }
                    }
                    &:nth-of-type(2) {
                        border-right: 1px solid $green;
                    }
                    &:nth-of-type(4) {
                        border-right: 1px solid #636466;
                        border-left: 1px solid #636466;
                    }
                    &:nth-of-type(4), &:nth-of-type(5) {
                        background-color: #F0F3F5;
                        text-align: center;
                    }
                }
                &:nth-of-type(even) {
                    td {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.parameter {
        tbody {
            tr:first-child {
                td {
                    &:first-of-type {
                        border-bottom: 1px solid $green;
                    }
                    &:nth-of-type(2), &:nth-of-type(3) {
                        border-bottom: 1px solid $green;
                    }
                    &:nth-of-type(4), &:nth-of-type(5) {
                        border-bottom: 1px solid #636466;
                    }
                }
            }
        }
    }
    &.dose {
        tr {
            th {
                width: 33.3333%;
            }
        }
    }
    &.duration {
        thead {
            tr {
                th {
                    &:first-of-type {
                        border-left: 1px solid $green!important;
                    }
                    width: 50%;
                    padding-left: 10px!important;
                    padding-right: 10px!important;
                }
            }
        }
        tbody {
            tr:first-child {
                td {
                    &:first-of-type {
                        background-color: #E9F5F6;
                    }
                }
            }
            tr {
                td:first-of-type {
                    padding-left: 10px;
                }
            }
        }
    }
    &.baseline {
        td.gradient {
            background: rgb(10,121,134);
            background: linear-gradient(90deg, rgba(10,121,134,1) 0%, rgba(183,221,226,1) 100%);
            strong { color: $white }
        }
        td[colspan="3"] {
            color: $green!important;
            border-top: 1px solid $green;
        }
        .green:first-of-type {
            border-left: 1px solid $green!important;
        }
        tr:nth-of-type(odd) {
            td:nth-of-type(3) {
                background-color: #F0F3F5!important;
                &.bgwhite {
                    background-color: #white!important;
                }
            }
        }
    }
    &.kaplan, &.ttni {
        tr {
            .green:first-of-type {
                border-left: 1px solid $green!important;
            }
            &:first-child {
                td[colspan="3"] {
                    background-color: #E9F5F6;
                    color: $green;
                }
            }
            td.blue {
                background-color: #E9F5F6;
            }
            .no-border-right {
                border-right: none!important;
            }
        }
    }
}