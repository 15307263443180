#servierONE, #section-next { max-width: 1240px; margin: 0px auto; }
#servierONE {
    width: 100%;
    max-width: unset!important;
    .container { max-width: 1075px; }
    .container > p { text-align: center; max-width: 720px; margin: 0px auto; }
    h3 {
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 10px;
    }
    p { font-size: 18px; line-height: 22px; padding-bottom: 25px; }
    @media screen and (max-width: 768px) {
        > h3 { padding: 0px 20px 10px; }
        > p { padding: 20px; }
    }
    h3, p { max-width: 725px; margin: 0px auto; text-align: center; }
    #container-more_info-title {
        background: #FFFFFF;
        border: 1px solid #00A39A;
        border-bottom: none;
        border-radius: 20px 20px 0px 0px;
        padding: 15px 50px 15px;
        color: #0A7986;
        text-align: center;
        font-weight: 600;
        @media screen and (max-width: 911px) {
            margin-left: 20px;
            margin-right: 20px;
            width: calc(100% - 40px);
        }
    }
    #container-more_info {
        background: #FFFFFF;
        border: 1px solid #00A39A;
        border-top: none;
        border-radius: 0px 0px 20px 20px;
        padding: 0px 50px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 911px) {
            flex-direction: column;
        }
        * { line-height: normal; }
        > div {
            display: flex;
            @media screen and (max-width: 911px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
        a { font-weight: bold; color: #D13E4E; }
        .icon { float: left; background-size: 100%; margin-right: 15px;
            @media screen and (max-width: 911px) {
                margin-right: 0px;
                margin-bottom: 12px;
            }
        }
        .or { color: #0A7986; font-weight: 600; font-size: 20px;
            @media screen and (max-width: 911px) {
                padding: 20px 0px;
            }
        }
        .call-hours { font-size: 13px; font-weight: bold; 
            span {
                font-size: 70%;
            }
        }
        .info_title { width: 100%; display: block; }
        
    }
    #more_info-gradient {
        width: 100%;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0%) 0%, rgba(255, 255, 255, 0%) 30%, #FFFFFF 31%, #FFFFFF 100%);
        @media screen and (max-width: 911px) {
            margin-left: 20px;
            margin-right: 20px;
            width: calc(100% - 40px);
            border-radius: 20px;
        }
    }
    #container-more_info-title, #container-more_info { max-width: 1074px; margin: 0px auto; }
    #info_visit {
        .icon { 
            background-repeat: no-repeat;
            width: 33px;
            height: 42px;
            background-image: url(../img/servierONE/icon-click.svg);
        }
    }
    #info_email {
        a { text-decoration: none; }
        .icon { 
            background-repeat: no-repeat;
            width: 40px;
            height: 28px;
            background-image: url(../img/servierONE/icon-email.svg);
        }
    }
    #info_call {
        a { text-decoration: none; }
        .icon { 
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            background-image: url(../img/servierONE/icon-call.svg);
        }
    } 
}