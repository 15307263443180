.cookies {
    display: none;
    position: fixed;
    width: 400px;
    height: 320px;
    padding: 45px 25px 40px 60px;
    top: calc(50% - 175px);
    right: 0;
    background-color: $white;
    z-index: 999;
    border-radius: 30px 0 0 30px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    div.header-three {
        font-weight: 800;
        color: $darkblue;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 0;
        color: $grey;
        margin-bottom: 16px;
        line-height: 20px;
    }
    img {
        position: absolute;
        top: -25px;
        left: -40px;
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
    a.button {
        font-size: 16px;
        line-height: 19px;
        height: 50px;
        min-width: 212px;
        &::after {
            top: 15px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 300px;
        height: auto;
        padding: 20px 20px 20px 30px;
        .row { padding: 20px 0 0!important; }
        div.header-three, p {
            font-size: 16px!important;
            line-height: 18px;
            margin-bottom: 10px;
        }
        a.button {
            font-size: 13px;
            line-height: 8px;
            margin-bottom: 10px;
            padding: 15px 30px;
            height: 38px;
            &::after {
                top: 9px;
            }
        }
        img {
            position: absolute;
            top: -10px;
            left: -15px;
        }
    }
}

body.modal-active {
    .cookies {
        display: none;
    }
}