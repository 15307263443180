.hero.voranigo-dosing h1 {
	@media screen and (max-width: 768px) { width: 130px; }
}
#main.voranigo-dosing {
	.product-shot {
		max-width: 365px;
	}
	.list-remember {
		margin-top: 30px;
		margin-left: 0;
		margin-bottom: 20px;
		@media screen and (max-width: 768px) {
			margin-left: 0;
		}
		h3 { margin-bottom: 5px;
			font-size: 18px;
			line-height: 20px;
		}
		.bullet { margin-left: 10px;
			font-size: 18px;
			line-height: 22px;
		} 
		.bullet::before { content: "•"; color: #00A39A; font-size: 16px; margin-left: -10px; margin-right: 5px; }
	}
	.items {
		margin-top: 15px;
		@media screen and (min-width: 600px) and (max-width: 1030px) {
			flex-wrap: wrap;
			padding: 20px;
		}
		.column { max-width: 350px; margin: 0px auto; text-align: center;
			@media screen and (min-width: 600px) and (max-width: 1030px) {
				flex: 0 0 100%;
				max-width: 100%;
				margin-top: 50px;
				> * { max-width: 500px; margin: 0 auto; }
			}
			@media screen and (max-width: 768px) {
				margin: 50px auto 0px;
			}
		}
		.title { font-size: 18px; line-height: 20px; font-weight: bold; color: #1B3667; max-width: 220px; display: inline-block; min-height: 50px; }
	}
	.icon {
		width: 90px; height: 104px;
		margin: 0px auto 20px;
		background-size: 90px 104px;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.icon-time { background-image: url(../img/voranigo-dosing/icon-time.svg); }
	.icon-thermometer { background-image: url(../img/voranigo-dosing/icon-thermometer.svg); }
	.icon-medications { background-image: url(../img/voranigo-dosing/icon-medications.svg); }
	hr { height: 1px; background-color: #1B3667; margin: 10px auto; }
	p { margin-bottom: 0px; }
	#product-shot-desktop {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	#product-shot-mobile {
		@media screen and (min-width: 769px) {
			display: none;
		}
	}
}