// header { background-color : green!important;  }
// header .container { background-color : blue!important;  }
.primary.opened + #nav-overlay-mobile {
    display: none;
    @media screen and (max-width: 1176px) {
        display: block;
        position: fixed;
        top: -70px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: #0A162C;
        opacity: 70%;
    }
}
.primary.opened.scrolled + #nav-overlay-mobile {
    top: -45px;
}
#container-nav {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 98;
}
#container-nav.scrolled {
    width: 100%;
    height: 120px;
    position: fixed;
    z-index: 17;
    @media screen and (max-width: 768px) {
        height: 85px;
        z-index: 98;
        top: 25px;
    }
}
#nav-menu {
    z-index: 101;
    display: inline-block;
    margin: 20px auto 20px;
    left: 50%;
    transform: translateX(-50%);
    .extra-support {
        display: none;
    }
    @media screen and (max-width: 1176px) {
        .extra-support {
            display: block;
            width: 100px;
            background-color: #D13E4E;
            padding: 8px 40px 7px 10px;
            border-radius: 5px !important;
            font-size: 11px;
            line-height: 13px;
            position: relative;
            background-image: url(../img/header/patient-support.svg);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: contain;
            border-radius: 0 0 25px 0;
            position: absolute;
            top: 2px;
            right: 70px;
            a {
                font-weight: 700;
                color: $white;
                &::after {
                    content: unset;
                }
            }
        }
    }
    @media screen and (max-width: 640px) {
        .extra-support {
            top: 5px;
        }
    }
}
#nav-logo {
    width: 285px;
    height: 85px;
    @media screen and (max-width: 768px) {
        width: 175px;
        height: 50px;    
    }
}
.mobile-nav {
    display: none;
    font-size: 19px;
    line-height: 13px;
    font-weight: light;
    cursor: pointer;
    @media screen and (max-width: 1176px) {
        display: block;
        position: absolute;
        top: 5px;
        right: 10px;
        width: 50px;
        height: 40px;
        border-top: 4px solid $darkred;
        border-bottom: 4px solid $darkred;
        color: $darkblue;
        padding-top: 10px;
        text-align: center;
        font-weight: 300;
        z-index: 999;
        &.opened {
            background-color: $lightred;
            color: $white;
            height: 50px;
            font-size: 2em;
            padding-top: 14px;
            border-radius: 10px 10px 0 0;
            &.scrolled {
                z-index: 999;
                height: 55px;   
            }
        }
        &.scrolled {
            position: fixed;
        }
    }
    @media screen and (min-width: 641px) and (max-width: 1176px) {
        top: 2px;
    }
}

nav.primary {
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
    color: $darkblue;
    font-size: 16px;
    line-height: 20px;
    z-index: 100;
    margin-top: 15px;
    ul > li {
        &:nth-of-type(4) {
            &::after {
                right: 15px!important;
            }
        }
    }
    @media screen and (max-width: 1176px) {
        display: none;
        &.opened {
            display: block;
            background-color: $white;
            z-index: 101;
            height: auto;
            top: 35px;
            background: white;
            position: fixed;
            left: 10px;
            width: calc(100% - 20px);
            padding: 0px 15px;
            z-index: 999;
            border-top: 2px solid #F398A4;
            padding-bottom: 0;
            border-radius: 0 0 15px 15px;
            ul {
                margin: 0;
                li {
                    border-bottom: 1px solid #F398A4!important;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 500;
                    &:nth-of-type(6) {
                        border-bottom: 0!important;
                    }
                    a {
                        width: 100%;
                        display: block;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 500;
                    }
                    &.access {
                        border-bottom: none!important;
                    }
                    &:nth-of-type(4) {
                        &::after {
                            left: 180px!important;
                        }
                    }
                    &.dropdown {
                        position: relative;
                        &::after {
                            content: "";
                            background-image: url(../img/content/caret.svg);
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 14px;
                            width: 14px;
                            height: 14px;
                            background-size: 14px 14px;
                            left: 210px;
                        }
                        span.no-arrow::after {
                            background-image: none;
                        }
                        span {
                            position: relative;
                            font-weight: 500;
                            &::after {
                                content: "";
                                background-image: url(../img/content/caret.svg);
                                background-repeat: no-repeat;
                                position: absolute;
                                right: -20px;
                                top: 7px;
                                width: 12px;
                                height: 12px;
                                background-size: 12px 12px;
                            }
                        }
                        &.opened {
                            ul {
                                display: block;
                                li a {
                                    font-size: 18px; line-height: 22px;
                                }
                            }
                            span::after {
                                transform: rotate(180deg);
                            }

                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    &.mobile {
                        background-color: $darkblue;
                        color: $white;
                        width: calc(100% + 30px);
                        position: relative;
                        left: -15px;
                        top: 0px;
                        border-radius: 0 0 15px 15px;
                        padding: 15px 25px;
                        a { 
                            color: $white;
                            &::after {
                                content: "›";
                                position: relative;
                                right: -5px;
                            }
                        }
                    }
                    &:last-of-type, ul li {
                        border-bottom: none!important;
                    }
                    ul {
                        li {
                            padding-left: 20px;
                            padding-top: 10px;
                            padding-bottom: 5px;
                            a {
                                font-weight: 400;
                            }
                            &:last-of-type {
                                padding-top: 5px;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        &.scrolled {
            top: 35px;
            @media screen and (max-width: 768px) {
                top: 40px
            }
        }
    }
    ul, li {
        list-style: none;
        ul {
            display: none;
        }
        li {
            a {
                color: $darkblue;
            }
            &.active {
                font-weight: 700;
                > a {
                    @media screen and (min-width: 1176px) {
                        border-bottom: 2px solid $lightred!important;
                    }
                    @media screen and (max-width: 768px) {
                        font-weight: 700;
                    }
                    span.no-underline {
                        border-bottom: 0;
                    }
                }
                .active > a {
                    border-bottom: 0!important;
                    font-weight: 700;
                }
            }
            @media screen and (max-width: 1175px) {
                &.dropdown.active > a {
                    font-weight: 700;
                    border-bottom: none !important;
                }
            }
        }
    }
    li:hover {
        font-weight: 700;
    }
    @media screen and (min-width: 1176px) {
        top: 0px;
        right: -90px;
        width: 732px;
        &.scrolled {
            > ul { position: fixed; max-width: 680px;  min-width: 680px; }
        }
        ul {
            display: flex;  
            justify-content: right;
            li {
                align-self: flex-end;
                height: 100%;
                min-height: 100%;
                width: 100%;
                position: relative;
                align-self: end;
                cursor: pointer;
                &:nth-of-type(6) {
                    padding-left: 20px;
                }
                &.dropdown {
                    position: relative;
                    &.active > a {
                        border-bottom: 2px solid #E8324A !important;
                    }
                    &::after {
                        content: "";
                        background-image: url(../img/content/caret.svg);
                        position: absolute;
                        height: 12px;
                        width: 15px;
                        right: 5px;
                        bottom: 0;
                        background-repeat: no-repeat;
                    }
                    &:last-of-type {
                        margin-right: 25px;
                    }
                    &:hover {
                        &::after {
                            bottom: 5px;
                            transform: rotate(180deg);
                        }
                    }
                    ul {
                        margin: 0;
                        position: absolute;
                        bottom: auto;
                        left: 0;
                        width: 170px;
                        background-color: $white;
                        border-radius: 0 0 15px 15px;
                        border-top: 2px solid $lightred;
                        box-shadow: 0px 3px 6px rgba(27, 54, 103, 0.16);
                        padding: 17px 15px 15px;
                        display: none;
                        li {
                            margin: 0 0 10px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid $lightred;
                            font-weight: normal;
                            font-size: 13px;
                            padding-bottom: 15px;
                            margin-bottom: 15px;
                            &:last-of-type {
                                margin-bottom: 0;
                                padding-bottom: 0;
                                border-bottom: none;
                            }
                        }
                    }
                    &.access {
                        ul {
                            width: 120px
                        }
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
            }
        }
        span {
            display: block;
        }
    }
}

.modal-active {
    .mobile-nav {
        z-index: 99;
    }
}