.hero {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1440px 348px;
    height: 340px;
    margin: 0 auto 50px;
    font-family: "fira-sans", Arial;
    position: relative;
    &.home {
        background-image: url(../img/hero/hero-home-desktop.jpg);
        background-size: auto 462px;
        @media screen and (min-width: 768px) and (max-width: 900px) {
            background-position-x: -400px;
        }
        @media screen and (max-width: 768px) {
            background-image: url(../img/hero/hero-home-mobile.jpg);
            background-size: auto 355px;
            .container {
                &::after {
                    bottom: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        background-size: 768px 226px;
        height: 290px;
        .container {
            background-position: bottom center;
        }
    }

    &.about-glioma, &.about-voranigo, &.voranigo-results, &.voranigo-dosing, &.possible-side-effects, &.financial-assistance-and-glioma-support, &.glioma-videos-and-resources, &.faqs, &.sitemap, &.error {
        z-index: 1;
        background-repeat: no-repeat;
        position: relative;
        @media screen and (min-width: 769px) {
            &::before {
                content: "";
                background-color: #f5fcfc;
                display: block;
                width: 100%;
                position: absolute;
                top: 46px;
                left: -50%;
                height: 294px;
            }
            &::after {
                content: "";
                background-color: #52B7B1;
                display: block;
                width: 100%;
                position: absolute;
                top: 47px;
                right: -50%;
                height: 293px;
            }
            .container {
                z-index: 1;
                background-size: cover;
            }
        }
    }

    &.about-glioma {
        .container {
            background-image: url(../img/hero/hero-about-glioma-desktop.jpg);
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-about-glioma-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
    }
    &.about-voranigo {
        .container {
            background-image: url(../img/hero/hero-about-voranigo-desktop.jpg);
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-about-voranigo-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
        &::after {
            background-color: #4CB5B1;
        }
    }
    &.voranigo-results {
        &::before {
            background-color: #E6F4F5;
        }
        &::after {
            background-color: #05A8A0;
        }
        .container {
            background-image: url(../img/hero/hero-voranigo-results-desktop.jpg);
            @media screen and (min-width: 769px) {
                background-position: right;
            }
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-voranigo-results-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
    }
    &.voranigo-dosing {
        .container {
            background-image: url(../img/hero/hero-voranigo-dosing-desktop.jpg);
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-voranigo-dosing-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
    }
    &.possible-side-effects {
        &::before {
            background-color: #E6F4F5;
        }
        &::after {
            background-color: #05A8A0;
        }
        .container {
            background-image: url(../img/hero/hero-possible-side-effects-desktop.jpg);
            @media screen and (min-width: 769px) {
                background-position: right;
            }
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-possible-side-effects-mobile.jpg);
                background-repeat: no-repeat;
                &::after {
                    bottom: 0;
                }
            }
        }
    }
    &.financial-assistance-and-glioma-support {
        .container {
            background-image: url(../img/hero/hero-financial-assistance-and-glioma-support-desktop.jpg);
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-financial-assistance-and-glioma-support-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
    }
    &.glioma-videos-and-resources {
        &::before {
            background-color: #E6F4F5;
        }
        &::after {
            background-color: #36afa6;
        }
        .container {
            background-image: url(../img/hero/hero-glioma-videos-and-resources-desktop.jpg);
            @media screen and (max-width: 768px) {
                background-image: url(../img/hero/hero-glioma-videos-and-resources-mobile.jpg);
                background-repeat: no-repeat;
            }
        }
    }
    &.faqs, &.sitemap, &.error {
        height: 150px;
        background-size: 150px;
        h1 {
            top: calc(50% - 26px);
        }
        &::before, &::after {
            height: 150px;
            top: 0;
        }
        .container {
            background-image: url(../img/hero/hero-faqs.jpg);
            background-position: center;
        }
        &::before {
            background-color: #F6FCFC;
        }
        &::after {
            background-color: #05A8A0;
        }
    }
    &.home { margin: 0px auto 0px; }
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    > .container {
        height: 100%;
        &::after {
            background-color: rgba(10, 121, 134, 0.75);
            content: "Actor portrayal.";
            position: absolute;
            bottom: 0;
            right: 20px;
            color: #fff;
            font-size: 12px;
            padding: 5px;
            border-radius: 5px 5px 0 0;
        }
        &.plural {
            &::after {
                content: "Actor portrayal."
            }
        }
        &.no-actor {
            &::after {
                content: unset;
                display: none;
            }
        }
    }

    &.main {
        background-color: red;
    }
    &.home {
        height: 462px;
        overflow: hidden;
    }
    sup {
        font-size:x-large; 
        vertical-align:super;
        top: 0.2em;
        @media screen and (max-width: 768px) {
            font-size: medium;
            top: 0.1em;
        }
    }
    .nav-header-link {
        position: relative;
        z-index: 11;
        @media screen and (max-width: 768px) {
            img { width: 180px; }
        }

        @media screen and (max-width: 380px) {
            img { width: 141px; }
        }
    }
    &.home {
        h1 {
            font-size: 80px;
            line-height: 70px;
            top: 50px;
            @media screen and (max-width: 768px) {
                font-size: 35px;
                line-height: 35px;;
                top: 0;
            }
        }
        .highlight {
            margin-top: 50px;
            @media screen and (min-width: 768px) {
                padding-right: 250px;
            }
        }
    }
    h1 {
        font-size: 44px;
        line-height: 52px;
        font-weight: 700;
        color: $darkblue;
        margin-bottom: 0px;
        z-index: 15;
        position: relative;
        top: calc(50% + 12px);
        @media screen and (min-width: 768px) {
            padding-right: 100px;
        }
        @media screen and (max-width: 768px) {
            font-size: 28px;
            line-height: 31px;
        }
        small {
            font-size: 36px;
            line-height: 38px;
            font-weight: 600;
            display: block;
            text-transform: none;
            @media screen and (max-width: 768px) {
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    &.subpage {
        h1 {
            font-size: 42px;
            line-height: 48px;
            font-weight: 600;
            text-transform: none;
            padding-bottom: 50px;
            @media screen and (max-width: 768px) {
                font-size: 24px;
                line-height: 28px;
                padding-bottom: 25px;
            }
        }
    }
    a.button {
        z-index: 11;
        @media screen and (max-width: 768px) {
            min-width: 145px;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 25px;
            margin-left: 25px;
        }
    }
    .press-release {
        margin-right: 30px;
        @media screen and (max-width: 768px) {
            margin-right: 10px;
        }

        @media screen and (max-width: 389px) {
            margin-bottom: 10px;
        }

    }
    .highlight {
        font-size: 32px;
        line-height: 38px;
        color: $darkblue;
        margin-top: 20px;
        margin-bottom: 24px;
        z-index: 12;
        position: relative;
        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 25px;
            sup {
                font-size: 70%;
            }
        }
    }
}

#main {
    width: 100%;
    max-width: unset;
    &.container {
        @media screen and (max-width: 1177px) {
            padding: 0px;
        }
    }
    #home-cards {
        background-image: linear-gradient(90deg, #1B3667 0%, #00A39A 100%);
        // @media screen and (max-width: 768px) {
        //     background-image: none;
        // }
        h2, p { color: #FFFFFF; text-align: left;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        h2 { font-size: 36px; line-height: 40px; }
        > div { max-width: 1250px; margin: 0px auto; }
        .column {
            display: flex; flex-flow: row wrap;
            p {
                @media screen and (min-width: 769px) {
                    flex-grow: 1;
                }
            }
            @media screen and (max-width: 768px) {
                justify-content: center;
            }
        }
        
    }
    .home-main {
        @media screen and (max-width: 768px) {
            background-image: linear-gradient(90deg, #1B3667 0%, #00A39A 100%);
            border-radius: 20px 0px 0px 20px;
            margin-left: 20px!important;
            .column h2 { font-size: 17px!important; line-height: 21px!important; }
            p { font-size: 14px; line-height: 18px; }
        }
    }
    .home-main .column { 
        padding: 60px 5% 40px;
        @media screen and (max-width: 768px) {
            padding: 20px 0px
        }
    }
    .cards {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%,#FFFFFF 51%, #FFFFFF 100%);
        padding: 20px;
        padding-bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: unset!important;
        @media screen and (max-width: 911px) {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 22%, #FFFFFF 22%, #FFFFFF 100%);
            flex-direction: column;
            align-items: center;
            margin-bottom: 23px;
        }
        .column {
            border: 1px solid #00A39A;
            border-radius: 20px;
            background-color: #FFFFFF;
            padding: 30px 25px;
            margin: 0px 10px;
            max-width: 360px;
            justify-content: right;
            position: relative;
            padding-top: 140px;
            &::before {
                position: absolute;
                left: 25px;
                top: 30px;
                @media screen and (max-width: 911px) {
                    left: unset;
                }
            }
            @media screen and (max-width: 911px) {
                margin-bottom: 20px;
            }
            h3 { margin-bottom: 0; display: block; width: 100%; font-size: 28px;
                @media screen and (max-width: 1160px) {
                    text-align: center;
                }
            }
            h2, p { color: #1B3667!important; margin: 0px auto; width: 100%; }
            h2 { font-size: 36px; line-height: 40px!important; }
            p { 
                margin-bottom: 20px;
                flex-grow: 1;
            }
            a {
                align-self: flex-end;
            }
        }
        // this is the icon¸
        > div::before {
            content: " ";
            display: block;
            height: 86px;
            width: 86px;
            background-color: #FFFFFF;
            background-size: auto 86px;
            background-repeat: no-repeat;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                background-position: center;
            }
        }
    }
    p { font-size: 18px; line-height: 22px; }
    h2 { margin-bottom: 8px; }
}
#card-glioma::before { background-image: url(../img/home/icon-glioma.svg); }
#card-results::before { background-image: url(../img/home/icon-results.svg); }
#card-dosing::before { background-image: url(../img/home/icon-dosing.svg); }