.flex {
    display: flex!important;
}

.vertical-align {
    align-items: center!important;
    justify-content: center!important;
}

.center {
    text-align: center!important;
}

.left {
    text-align: left!important;
}

.right {
    text-align: right!important;
}

.justify-left {
    justify-content: left!important;
}

.justify-right {
    justify-content: right!important;
}

.normal {
    font-weight: 400!important;
}

.strong {
    font-weight: 700;
}

strong {
    &.light {
        font-weight: 500;
    }
}

.darkblue {
    color: $darkblue;
}

.bodygrey {
    color: #636466!important;
}

.tablegrey {
    color: #666666!important;
}

.red {
    color: $lightred;
}

.green {
    color: $green;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-10 {
    margin-bottom: 10px!important;
}

.margin-bottom-4 {
    margin-bottom: 4px!important;
}

.margin-bottom-0 {
    margin-bottom: 0px!important;
}

.margin-top-30 {
    margin-top:30px!important;
}

.margin-top-25 {
    margin-top:25px!important;
}

.margin-top-20 {
    margin-top:20px!important;
}

.margin-top-15 {
    margin-top:15px!important;
}

.margin-top-0 {
    margin-top: 0px!important;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.desktop {
    display: block!important;
}

.mobile {
    display: none!important;
}

.relative {
    position: relative;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

hr {
    max-width: 1130px;
    margin: 0px auto;
    &.green {
        border-color: $green;
    }
    &.red {
        border-color: #F398A4;
    }
    &.blue {
        border-color: $darkblue;
    }
    &.grey {
        border-color: $bordergrey;
    }
    &.short {
        max-width: 920px;
    }
    &.green-divider {
        height: 4px;
        background-image: linear-gradient(90deg, rgba(0, 163, 154, 1) 0%, rgba(183, 221, 226, 0.4) 100% );
    }
}

p.clickthrough {
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
        line-height: 16px;
    }
}

#main {
    scroll-margin-top: 200px;
}

#patient-story {
    scroll-margin-top: 200px;
}

a.with-arrow {
    color: $lightred;
    font-weight: 700;
    position: relative;
    font-size: 18px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }
    &::after {
        content: "›";
        position: absolute;
        right: -10px;
    }
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none!important;
    }
    
    .mobile {
        display: block!important;
    }
    .margin-top-mobile-30 {
        margin-top: 30px;
    }
}