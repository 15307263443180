#main.voranigo-results {
	.row:first-of-type {
		.column {
			@media screen and (max-width: 768px) {
				max-width: 100%;
				flex: 0 0 100%;
				display: flex;
				flex-direction: column;
				order: -1; /* This will move the content above the clinical trial data section */
				h2, p {
						max-width: 100%;
						padding-right: 20px;
				}
				h2 { 
					order: 1;
				}
				p:first-of-type {
					order: 2;
				}
				p:last-of-type {
					order: 3
				}
				#clinical-data-mobile {
					order: 4;
				}
			}
		}
	}
	.column:first-of-type {
		h2 { max-width: 500px; }
		p { max-width: 550px; margin-bottom: 8px; }
	}
	.footnote, .reference {
		font-size: 12px;
		line-height: 15px;
		text-align: left;
		margin: 0 0 4px;
		position: relative;
		&.with-margin {
				margin: 0 0 10px;
		}
		&.gutter {
				padding-left: 5px;
				sup.first {
						position: absolute;
						left: 0px;
						top: 1px;
				}
				sup.asterisk {
						font-size: 100%;
				}
		}
}
}
.clinical-trial-data {
	background-color: #EDF7F8;
	padding: 50px 30px;
	text-align: center;
	@media screen and (max-width: 768px) {
		padding: 15px; 
	}
}

#card div {
	padding: 40px 1rem 25px;
	@media screen and (max-width: 1024px) {
		padding: 40px calc(20px + 1rem) 25px;
	}
	@media screen and (max-width: 768px) {
		padding: 40px 20px 25px;
	}
}

#voranigo-results-why.why-voranigo-was-right {
	width: 822px;
	height: 245px;
	background-image: url(../img/voranigo-results/bg-why-rob-2-desktop.jpg);
	background-position: right;
	background-size: 100%;
	background-repeat: no-repeat;
	margin: 0px auto;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	color: #FFFFFF;
	padding-right: 45px;
	padding-bottom: 45px;
	@media screen and (max-width: 768px) {
		background-image: url(../img/voranigo-results/bg-why-rob-2-mobile.jpg);
		background-position: center bottom;
		max-width: 348px;
		width: 100%;
		height: 440px;
		padding: 25px 50px;
	}
	p {
		font-size: 28px;
		font-weight: 500;
		@media screen and (max-width: 768px) {
			font-size: 24px;
			line-height: 26px;
		}
	}
	.button {
		border-color: #FFFFFF;
		@media screen and (max-width: 768px) {
	    	margin: 0px auto;
	    }
	}
	.why-voranigo-cta {
		display: flex;
	    flex-direction: column;
	    align-items: flex-end;
	}
}

@media screen and (max-width: 768px) {
	#clinical-data-desktop { display: none; }
	.hero.voranigo-results {
		h1 { width: 130px; }
	}
}

@media screen and (min-width: 769px) {
	#clinical-data-mobile { display: none; }
}

#clinical-data-mobile { 
	margin: 20px auto;
	p.footnote { margin-bottom: 0px; }
}