#isi {
    margin-top: 55px;
    scroll-margin-top: 350px;
    @media screen and (max-width: 768px) {
        scroll-margin-top: 270px;
    }
}
#isi, .overlay-isi {
    div.header-two {
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: $darkblue;
        margin-bottom: 8px;
        margin-top: 7px;
    }
    div.header-three {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #636466;
        margin-bottom: 6px;
    }
    p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
        &.pad-top-12 {
            padding-top: 12px;
        }
    }
    a {
        color: $darkred;
        text-decoration: underline;
        padding-top: 20px;
    }
    .bullet {
        margin-left: 12px;
        &::before {
            content: "•";
            color: #00A39A;
            font-weight: bold;
            margin-left: -12px;
            margin-right: 5px;
        }
    }
    .dash {
        margin-left: 32px;
        &::before {
            content: "—";
            margin-left: -18px;
            margin-right: 5px;
        }
    }
    .isi-section {
        .row {
            display: block;
        }
    }
}

.overlay-isi {
    box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.1);
    z-index: 999;
    position: fixed;
    width: 85%;
    left: 7.5%;
    right: 7.5%;
    bottom: 0;
    height: 150px;
    background: $white;
    display: block;
    padding: 25px;
    border-radius: 20px 20px 0 0px;
    img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
        height: 200px;
        width: calc(100% - 20px);
        left: 10px;
        right: 10px;
        padding: 10px;
        .row {
            flex-direction: column-reverse;
        }
        div.header-two {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 6px;
        }
        div.header-three {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 6px;
            
        }
        img {
            position: absolute;
            top: 12px;
            right: 10px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}