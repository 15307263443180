$darkblue: #1B3667;
$lightblue: #EDF7F8;
$green: #0A7986;
$darkred: #D13E4E;
$lightred: #E8324A;
$white: #fff;
$grey: #626366;
$tablegrey: #666666;
$bordergrey: #8C9AB2;
$body: #222;
$black: #000;