/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * fira-sans:
 *   - http://typekit.com/eulas/00000000000000007735a3a4
 *   - http://typekit.com/eulas/00000000000000007735a3a7
 *   - http://typekit.com/eulas/00000000000000007735a3a9
 *   - http://typekit.com/eulas/00000000000000007735a3ab
 *   - http://typekit.com/eulas/00000000000000007735a3b1
 *   - http://typekit.com/eulas/00000000000000007735a3b3
 *   - http://typekit.com/eulas/00000000000000007735a3ba
 *   - http://typekit.com/eulas/00000000000000007735a3bb
 *   - http://typekit.com/eulas/00000000000000007735a3bf
 *   - http://typekit.com/eulas/00000000000000007735a3c6
 * fira-sans-condensed:
 *   - http://typekit.com/eulas/00000000000000007735a3c7
 *   - http://typekit.com/eulas/00000000000000007735a3ca
 *   - http://typekit.com/eulas/00000000000000007735a3cd
 *   - http://typekit.com/eulas/00000000000000007735a3d0
 *   - http://typekit.com/eulas/00000000000000007735a3de
 *   - http://typekit.com/eulas/00000000000000007735a3e0
 *   - http://typekit.com/eulas/00000000000000007735a3e3
 *   - http://typekit.com/eulas/00000000000000007735a3e6
 *
 * © 2009-2025 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2024-04-08 15:35:43 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=zlq1ckc&ht=tk&f=22489.22490.22491.22492.22495.22496.24684.24685.24689.24690.38449.38450.38453.38454.38457.38458.38461.38462&a=186014901&app=typekit&e=css");

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/e8c22b/00000000000000007735a3a4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e8c22b/00000000000000007735a3a4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e8c22b/00000000000000007735a3a4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/396947/00000000000000007735a3a7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/396947/00000000000000007735a3a7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/396947/00000000000000007735a3a7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/3f4c3a/00000000000000007735a3a9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/3f4c3a/00000000000000007735a3a9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/3f4c3a/00000000000000007735a3a9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/e45fab/00000000000000007735a3ab/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/e45fab/00000000000000007735a3ab/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/e45fab/00000000000000007735a3ab/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/175b25/00000000000000007735a3b1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/175b25/00000000000000007735a3b1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/175b25/00000000000000007735a3b1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/84a20c/00000000000000007735a3b3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/84a20c/00000000000000007735a3b3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/84a20c/00000000000000007735a3b3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/848799/00000000000000007735a3ba/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/848799/00000000000000007735a3ba/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/848799/00000000000000007735a3ba/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/713199/00000000000000007735a3bb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"),url("https://use.typekit.net/af/713199/00000000000000007735a3bb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"),url("https://use.typekit.net/af/713199/00000000000000007735a3bb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/fbf053/00000000000000007735a3bf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/fbf053/00000000000000007735a3bf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/fbf053/00000000000000007735a3bf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"fira-sans";
src:url("https://use.typekit.net/af/e42a9c/00000000000000007735a3c6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/e42a9c/00000000000000007735a3c6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/e42a9c/00000000000000007735a3c6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/75c092/00000000000000007735a3c7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/75c092/00000000000000007735a3c7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/75c092/00000000000000007735a3c7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/05a3ef/00000000000000007735a3ca/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/05a3ef/00000000000000007735a3ca/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/05a3ef/00000000000000007735a3ca/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/d175c8/00000000000000007735a3cd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/d175c8/00000000000000007735a3cd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/d175c8/00000000000000007735a3cd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/220857/00000000000000007735a3d0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/220857/00000000000000007735a3d0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/220857/00000000000000007735a3d0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/c3bbb7/00000000000000007735a3de/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c3bbb7/00000000000000007735a3de/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c3bbb7/00000000000000007735a3de/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/a5247f/00000000000000007735a3e0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/a5247f/00000000000000007735a3e0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/a5247f/00000000000000007735a3e0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/8f78de/00000000000000007735a3e3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/8f78de/00000000000000007735a3e3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/8f78de/00000000000000007735a3e3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"fira-sans-condensed";
src:url("https://use.typekit.net/af/147f4e/00000000000000007735a3e6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/147f4e/00000000000000007735a3e6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/147f4e/00000000000000007735a3e6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

.tk-fira-sans { font-family: "fira-sans",sans-serif; }
.tk-fira-sans-condensed { font-family: "fira-sans-condensed",sans-serif; }