.hero.possible-side-effects h1 {
	@media screen and (max-width: 768px) { width: 260px; }	
}
#main.possible-side-effects {
	.row:first-of-type {
		.column-50 {
			@media screen and (max-width: 1000px) {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
		.column:last-of-type {
			@media screen and (min-width: 1001px) {
				max-width: 500px;
			}
			align-self: center;
			p { margin-bottom: 10px; }
		}
	}
	.row {
		@media screen and (max-width: 1000px) {
			flex-wrap: wrap;
		}
	}
	.most-common {
		padding-top: 10px;
		margin-bottom: 10px;
		font-weight: 700;
		color: $darkblue;
	}
	.list-remember {
		margin-top: 0;
    margin-left: 0;
    margin-bottom: 20px;
    @media screen and (max-width: 1000px) {
        margin-left: 0;
				flex: 0 0 100%;
				max-width: 100%;
				&:first-of-type {
					margin-bottom: 0;
				}
    }
    h3 { margin-bottom: 5px; }
    .bullet { 
        margin-left: 10px; 
        text-align: left;
				font-size: 18px;
				line-height: 22px;
    } 
    .bullet::before { 
        content: "•"; 
        color: #00A39A; 
        font-size: 16px; 
        margin-left: -10px; 
        margin-right: 5px; 
    }
	}
	hr { max-width: 1030px;
		&:last-of-type { margin-bottom: 0px; }
	}
	.monitoring-intro { text-align: center; max-width: 816px; margin-bottom: 5px; 
		@media screen and (max-width: 1000px) {
			padding: 0px 20px;
		}
	}
	.monitoring-items {
		max-width: 1000px;
		@media screen and (max-width: 1000px) {
			justify-content: center;
		}
		> .column { 
			display: flex;
			@media screen and (max-width: 1000px) {
				padding: 0px 20px;
				flex-direction: column;
				align-items: center;
				.column-icon {
					margin: 15px auto 30px;
				}
			}	
		}
		> .column:first-of-type { max-width: 470px; 
			@media screen and (max-width: 1000px) {
				max-width: 500px;
			}
		}
		> .column:last-of-type { max-width: 530px;
			@media screen and (max-width: 1000px) {
				max-width: 500px;
			}
		}
	}
	.monitoring-text { margin: 0px 15px; }
	.column-icon, .icon { width: 99px; min-width: 99px; height: 96px; }
	.icon { background-repeat: no-repeat; background-size: 99px 96px; }
	.icon-2-weeks { background-image: url(../img/possible-side-effects/calendar-every-2-weeks.svg); }
	.icon-once-monthly { background-image: url(../img/possible-side-effects/calendar-once-monthly.svg); }
	.provider-patient {
		position: relative;
		&::after {
			background-color: rgba(10, 121, 134, 0.75);
			content: "Actor portrayal.";
			position: absolute;
			bottom: 0;
			right: 0;
			color: #fff;
			font-size: 12px;
			padding: 5px;
			border-radius: 5px 0 15px 0;
			@media screen and (max-width: 1000px) {
				border-radius: 5px 0 10px 0;
			}
		}
	}
	#provider-patient-desktop {
		@media screen and (max-width: 1000px) { display: none; }
	}
	#provider-patient-mobile {
		margin: 0px 20px; 
		img { width: 100%; }
		@media screen and (min-width: 1001px) { display: none; }
	}
}