#main{
	&.about-glioma {
		.width-510 {
			max-width: 510px;
			@media screen and (max-width: 1030px) {
				max-width: 100%;
			}
		}
		+ .wrapper-bottom-gradient #section-next {
			padding-top: 20px;
		} 
	}
	.green-divider {
		margin: 35px auto;
	}
}
.row-download-glioma {
	max-width: 650px;
	margin: 45px auto 25px;
	.guide-cover {
		float: left;
		img { width: 132px; margin-right: 30px;
			@media screen and (max-width: 768px) {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
	.guide-title {
		font-size: 28px;
		line-height: 30px;
		color: #1B3667;
		font-weight: 500;
		max-width: 510px;
		margin-left: 100px;
		padding-top: 10px;
	}
	.button {
		display: inline-block;
		margin-left: 0;
		margin-top: 10px;
	}
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		.guide-cover { float: unset; order: 2; margin-top: 20px; }
		.guide-title { margin: 0px 40px; text-align: center; order: 1; }
		.button { margin: 25px auto 0px; order: 3; }
	}
}
.row-narrow {
	padding-left: 10%!important;
	padding-right: 10%!important;
}
.green-divider {
	max-width: 1000px;
	width: 100%;
}
#about_glioma-map-desktop {
	width: 500px;
	height: 311px;
	margin-right: 10px;
	background-image: url(../img/about-glioma/us-map.svg);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center;
	min-width: 506px;
	@media screen and (max-width: 1030px) {
		display: none;
	} 
}

#about_glioma-map-mobile {
	width: 320px;
	height: auto;
	background-image: url(../img/about-glioma/us-map.svg);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center;
	min-width: 320px;
	margin: 0px auto 30px;
	svg { width: 320px; height: auto; }
	@media screen and (min-width: 1030px) {
		display: none;
	} 
}

.with-icon {
    padding: 5px 40px 15px 80px!important;
    background-repeat: no-repeat;
    background-position: 0px;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
    	padding: 85px 0px 15px!important;
    	background-position: center top;
    }
    p { margin-bottom: 5px; }
    p:last-of-type { margin-bottom: 0px; }
}
.icon-brain {
    background-image: url(../img/about-glioma/icon-brain.svg);
    background-size: 64px 62px;
}
.icon-test {
    background-image: url(../img/about-glioma/icon-test.svg);
    background-size: 53px 62px;
}