.sitemap {
    ul, li {
        list-style: none;
        @media screen and (max-width: 768px) {
            padding-left: 0;
            margin-left: 0;
        }
    }
    ul {
        li {
            &::before {
                display: none;
            }
            margin-bottom: 20px;
            color: $darkred;
            strong {
                color: #636466;
                font-size: 18px;
                line-height: 24px;
            }
            a {
                font-size: 18px;
                line-height: 24px;
                color: $darkred;
                text-decoration: underline;
                font-weight: bold;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }
            &.indented {
                margin-left: 20px;
                a {
                    font-weight: normal;
                }
            }
        }
    }
}