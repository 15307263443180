header {
    z-index: 10;
    position: fixed;
    width: 100%;
    display: block;
    font-size: 13px;
    font-family: "fira-sans", Arial;
    background: #B7DDE2;
    background: linear-gradient(90deg, #B7DDE2 0%, #B7DDE2 50%, #FFFFFF 51%, #FFFFFF 100%);
    z-index: 98;
    top: 0;
    @media screen and (max-width: 1177px) {
        .row {
            &:first-of-type {
                padding-left: 10px;
                padding-right: 10px;
                background-color: rgba(255, 255, 255, 0.6)!important;
                width: calc(100% + 20px);
                position: relative;
                left: -10px;
            }
        }
    }
    .for-patients {
        display: none;
        @media screen and (max-width: 1177px) {
            display: block;
            position: absolute;
            top: 13px;
            right: 10px;
            color: $darkred;
            a {
                color: $darkblue;
                font-weight: normal;
                font-style: normal;
            }
        }
    }

    .connect-with-a-rep {
        display: none;
        @media screen and (max-width: 1177px) {
            display: block;
            position: absolute;
            top: 100px;
            right: 80px;
            color: $white;
            background-color: $darkred;
            padding: 10px;
            border-radius: 10px;
            font-size: 11px;
            line-height: 13px;
            font-weight: 700;
            width: 120px;
            padding-right: 20px;
            background-image: url(../img/header/patient-support.svg);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-position-x: 80px;
            background-size: contain;
            &.extra {
                top: 77px;
                height: 46px;
                width: 105px;
            }
            a {
                color: $white;
                font-size: 11px;
                line-height: 13px;
            }
        }
        @media screen and (max-width: 768px) {
            top: 97px;
            width: 105px;
            background-position-x: 65px;
        }
    }

    .healthcare {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        font-style: italic;
        @media screen and (max-width: 1177px) {
            font-size: 11px;
            line-height: 13px;
            padding-top: 8px;
            text-align: center;
            width: 100%;
            background-color: #fff;
        }

        @media screen and (max-width: 639px) {
            padding-bottom: 3px;
        }
    }

    .pi {
        @media screen and (max-width: 1177px) {
            padding-bottom: 3px;
            ul {
                text-align: center;
            }
        }
        
        @media screen and (max-width: 639px) {
            display: flex;
            justify-content: center;
        }
    }

    .supplemental-nav {
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        opacity: 1;

        .mobile-nav {
            display: none;
            @media screen and (max-width: 1000px) {
                display: block;
                position: fixed;
                top: 78px;
                right: 10px;
                font-weight: 300;
                z-index: 100;
            }
        }

        @media screen and (max-width: 768px) {
            padding-top: 5px;
            padding-bottom: 0;
        }
        img {
            height: 55px;
            opacity: 1;
            @media screen and (max-width: 768px) {
                height: 42px;
            }
        }
        @media screen and (max-width: 639px) {
            .connect-with-a-rep {
                top: 65px;
                display: block;
            }
        }
    }

    &.scrolled {
        @media screen and (max-width: 768px) {
            top: 23px;
            .healthcare {
                display: none;
            }
            .pi {
                transform: translateY(-45px);
                padding-top: 0;
                background-color: #fff;
                width: 100%;
                left: 0;
                position: absolute;
            }
        }
        @media screen and (max-width: 1177px) {
            .pi { padding-top: 23px; }
            .healthcare { position: fixed; top: 0px; }
            .connect-with-a-rep.extra {
                height: 46px;
                width: 105px;
                top: 97px;
            }
        }
    }

    &:not(.scrolled) {
        position: absolute;
        top: 0px;
        .supplemental-nav {
            opacity: 0;
            display: none;
            img {
                opacity: 0;
            }
            .connect-with-a-rep {
                display: block;
            }
        }
        
        @media screen and (max-width: 639px) {
            .connect-with-a-rep.extra {
                display: block;
                top: 75px;
            }
        }
    }
    &.scrolled {
        .container {
            padding-left: 0!important;
            .pi {
                ul { right: 0; }
            }
        }
    }
    .container {
        background-color: #B7DDE2;
        border-radius: 0 0 1em;
        @media screen and (max-width: 1177px) {
            background-color: #FFFFFF;
        }
        .row:first-of-type {
            border-radius: 0 0 25px 0;
            position: relative;
            @media screen and (max-width: 1177px) {
                background-color: unset;
                border-radius: 0;
            }
            @media screen and (max-width: 639px) {
                min-height: 56px;
            }
            &::before {
                content: "";
                width: 50%;
                position: absolute;
                left: -50%;
                top: 0;
                height: 100%;
                @media screen and (max-width: 1177px) {
                    display: none;
                }
            }
        }
        nav {
            margin-bottom: 0;
            ul {
                margin-bottom: 0;
                display: flex;
                justify-content: space-around;
                position: relative;
                right: -10px;
                .nav-pipe { color: #D13E4E; display: flex; align-items: center; text-align: center;
                    @media screen and (max-width: 768px) {
                        padding: 0 5px;
                    }
                }
                .mobile-hide { 
                    @media screen and (max-width: 639px) {
                        display: none;
                    }
                }
                li {
                    list-style: none;
                    margin-bottom: 0;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 3px;
                    @media screen and (min-width: 769px) {
                        &:last-of-type {
                            padding-right: 40px;
                        }
                    }
                    a {
                        color: $darkblue;
                        text-decoration: none;
                        line-height: 1;
                        position: relative;
                    }
                    &.patient-support {
                        background-color: $darkred;
                        padding-left: 20px;
                        padding-right: 50px;
                        position: relative;
                        background-image: url("../img/header/patient-support.svg");
                        background-repeat: no-repeat;
                        background-position: right bottom;
                        border-radius: 0 0 15px 0;
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                        a {
                            font-weight: 700;
                            color: $white;
                            &::after {
                                content: unset;
                            }
                        }
                    }

                    &:nth-of-type(4) {
                        a {
                            &::after {
                                content: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 640px) and (max-width: 1177px) {
        .row {
            display: block!important;
            &:first-of-type {
                left: 0;
                top: -5px;
            }
            .healthcare {
                text-align: center;
                padding-top: 10px;
            }
            .column-66, .column-34 {
                display: block;
                max-width: 100%;
                flex: 0 0 100%!important;
                max-width: 100%!important;
                ul {
                    li {
                        display: inline-block;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
                .for-patients {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 639px) {
        nav {
            ul {
                right: unset!important;
                justify-content: center!important;
                li {
                    width: auto!important;
                }
            }
        }
        .column-66, .column-34 {
            flex: 0 0 100%!important;
            max-width: 100%!important;
            li {
                a {
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.1px;
                }
            }
        }
        .column-66 {
            ul {
                li{
                    padding-left: 0!important;
                    padding-right: 0;
                    width: 50%;
                    padding-top: 4px!important;
                    padding-bottom: 4px!important;
                    &:first-of-type {
                        display: none;
                    }
                    a {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        .column-66, .column-34 {
            li {
                a {
                    letter-spacing: -0.3px;
                }
            }
        }
    }
    @media screen and (max-width: 356px) {
        .column-66, .column-34 {
            li {
                a {
                    letter-spacing: -0.4px;
                }
            }
        }
    }
    @media screen and (max-width: 389px) {
        .column-66, .column-34 {
            li {
                a {
                    font-size: 10px;
                    line-height: 13px;
                }
            }
        }
        .for-patients {
            font-size: 10px;
            line-height: 13px;
        }
    }
}
