.accordion, .accordion-faq {
    max-width: 1000px;
    margin-bottom: 25px;

    h4 { 
        margin-top: 0!important;
    }

    &::after {
        content: "";
        width: 100%;
        height: 10px;
        background-image: url("../img/content/shadow.svg");
        display: block;
        position: relative;
        background-repeat: no-repeat;
        @media screen and (max-width: 768px) {
            background-image: url("../img/content/shadow-mobile.svg");
            width: 350px;
            margin: 0 auto;
        }
    }

    a { cursor: pointer; }

    p.clickthrough {
        margin-bottom: 10px;
    }

    .network, .specialty {
        padding-left: 150px;
        padding-right: 100px;
        margin-bottom: 6px;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            padding-left: 100px;
            padding-right: 10px;
        }

        @media screen and (max-width: 400px) {
            padding-right: 40px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 50px;
            width: 85px;
            height: 85px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 768px) {
                left: 10px;
                top: -5px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../img/content/toggle.svg");
            width: 33px;
            height: 33px;
            right: 0;
            transform: rotate(180deg);
            @media screen and (max-width: 768px) {
                top: 15px;
            }

            @media screen and (max-width: 500px) {
                top: 0px;
            }
        }
        &.rotate::after {
            transform: rotate(0deg);
        }
    }
    .network {
        padding-top: 7px;
        &::before, &::after {
            top: -7px;
            @media screen and (max-width: 768px) {
                top: 0;
            }
        }
        &::after {
            @media screen and (max-width: 768px) {
                top: 15px;
            }
            @media screen and (max-width: 500px) {
                top: 5px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        h3 {
            font-size: 20px;
            line-height: 24px;
        }
    }
    h4, h5 {
        margin-bottom: 15px
    }
    p.description {
        padding-left: 150px;
        padding-right: 100px;
        min-height: 50px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        @media screen and (max-width: 768px) {
            padding-top: 10px;
            padding-left: 100px;
            padding-right: 15px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 30px;
        }
    }
    .network::before {
        background-image: url("../img/content/network.svg");
    }
    .specialty::before {
        background-image: url("../img/content/specialty.svg");
    }
    li {
        list-style: none;
        padding-left: 40px;
        text-wrap: wrap;
        .icon {
            position: relative;
            display: inline-block;
            font-size: 16px;
            span {
                @media screen and (max-width: 430px) {
                    display: inline-block;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: -40px;
                width: 27px;
                height: 27px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            &.phone {
                color: $grey;
                text-decoration: none;
                &::before {
                    background-image: url("../img/content/phone.svg");
                }
            }
            &.fax {
                color: $grey;
                text-decoration: none;
                &::before {
                    background-image: url("../img/content/fax.svg");
                }
            }
            &.mail::before {
                background-image: url("../img/content/mail.svg");
            }
            &.website::before {
                background-image: url("../img/content/website.svg");
            }
        }
    }
    .answer {
        display: none;
        .row {
            margin-top: 35px;
            &:first-of-type {
                margin-top: 25px;
                @media screen and (max-width: 768px) {
                    margin-top: 0;
                }
            }
            ul {
                margin-bottom: 0;
                list-style: none;
                padding-left: 17px;;
                li {
                    &::before {
                        display: none;
                    }
                    .faq-li-sub::before { content: "—"; color: #00A39A; margin-left: -18px; padding-right: 5px; font-weight: bold; }
                    .faq-li-sub { margin-left: 27px; }
                }
            }
            .column { padding: 0px; }
            a { color: #E8324A; font-size: 18px; line-height: 22px; font-weight: bold; text-decoration: underline; }
        }
        @media screen and (max-width: 768px) {
            padding: 10px 0 25px;
        }
        &.opened {
            display: block;
        }
    }
}

.accordion-faq {
    max-width: 100%;
    @media screen and (max-width: 1200px) {
        max-width: 98%;
    }
    &::after {
        display: none;
    }
    .column {
        &.website {
            padding-top: 12px;
            padding-left: 0;
            a {
                font-weight: 700;
            }
            &::before {
                background-image: url("../img/content/website.svg");
                width: 33px;
                height: 33px;
                top: 7px;
                left: -40px;
            }
        }
    
        &.phone {
            padding-top: 2px;
            padding-left: 0;
            margin-left: 70px;
            a {
                text-decoration: none;
                font-weight: 700;
            }
            &::before {
                background-image: url("../img/content/phone.svg");
                width: 33px;
                height: 33px;
                top: 3px;
                left: -40px;
            }
        }
    }
    .item {
        padding-top: 25px;
        &:first-of-type {
            padding-top: 0;
        }
        &::after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            background-image: linear-gradient(90deg, rgba(0, 163, 154, 1) 0%, rgba(183, 221, 226, 0.4) 100% );
        }
        h3 {
            position: relative;
            font-size: 24px;
            line-height: 28px;
            color: $darkblue;
            font-weight: 500;
            margin-bottom: 15px;
            @media screen and (min-width: 860px) and (max-width: 1000px) {
                padding-right: 100px;
            }
            
            @media screen and (max-width: 768px) {
                padding: 0 35px 0 20px;
            }
            cursor: pointer;
            
            &::after {
                content: "";
                background-image: url("../img/content/caret-white.svg");
                background-color: $lightred;
                background-repeat: no-repeat;
                width: 33px;
                height: 33px;
                display: block;
                border-radius: 50%;
                text-align: center;
                background-position: center;
                background-size: 60%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .answer {
            padding-bottom: 10px;
            @media screen and (min-width: 768px) {
                padding-right: 100px;
            }
            .row {
                margin-top: 0;
                padding-top: 0!important;
            }
            ul {
                li {
                    list-style: disc;
                    padding-left: 0;
                    padding-top: 2px;
                    margin-bottom: 0px;
                    font-size: 18px;
                    line-height: 22px;
                    &::marker {
                        color: $green;
                    }
                }
            }
            h3 {
                color: $green;
                padding-left: 0;
                &::after {
                    display: none;
                }
            }
            p { margin-bottom: 5px; }
        }
        &.item-opened {
            h3 {
                &::after {
                    transform: rotate(180deg);
                }
            }
            .phone-callout {
                .row {
                    margin-top: 0;
                    @media screen and (max-width: 768px) {
                        .website, .phone {
                            margin-left: 0;
                            padding: 10px 0 0 0;
                            width: 250px!important;
                            max-width: 250px!important;
                            text-align: left;
                            padding-left: 50px;
                            &::before {
                                left: 0;
                            }
                        }
                        .website {
                            padding-top: 15px;
                        }
                    }
                }
            }
        }
    }
}